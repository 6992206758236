module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Peitz Vermessung - Ihre Ansprechstelle für Vermessungsangelegenheiten!","short_name":"Peitz Vermessung","start_url":"/","display":"standalone","icon":"src/images/Logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"578ae0b4e7f122f71f8082860ac515ed"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
